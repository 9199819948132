import { GeneratorCategory } from '../../components/_organisms/DesignerTabPane/GeneratorsTab/utils';
import { Slide } from '../../components/SlideComponents/slide.types';
import { LessonAttachment } from '../backendService/backendService';
import { ActiveGame, ById, Prompt, PromptInput, Round } from '../backendService/types';

export enum GeneratorCoarseLicenseLevel {
  freemium = 'freemium',
  premium = 'premium',
  enterprise = 'enterprise',
}

export interface Template {
  id: string;
  type: 'template';
  parentId: string | null;
  tenantId: string;
  title?: string | null;
  description?: string | null;
  lastPublished?: number;
  isPublic?: boolean;
  isPrivate: boolean;
  coverImageId?: string | null;
  coverImageAltText?: string | null;
  clonedFromTemplateId?: string | null;
  createdAt: number;
  requireRealNames?: boolean;
  currentRound?: string | null;
  modifiedAt: number;
  metadata?: ById<string | boolean | number> | null;
  topicId?: string | null;
  isDeleted?: boolean | null;
  deletedAt?: number | null;
  language?: string | null; // en, nb-NO, de
  templateSharingPublic?: boolean | null;
  isBlockedFromSharing?: boolean | null;
  creator: {
    uid: string;
  };
  members: string[];
  content: {
    rounds: Round[] | null;
    slides?: Slide[] | null;
    gameIds: string[];
    activeGame?: ActiveGame;
  };
  aiContent?: AiContent;
  attachments?: LessonAttachment[];
}

export type AiContent = {
  prompts: Prompt[]; // <- one to many with elements of type aiTemplateOutput
  promptInputs: PromptInput[]; // the inputs consumers are exposed to
  categories: GeneratorCategory[];
  subjects?: string[];
  grades?: string[];
  promptInputSuggestions: PromptInput[][];
  isPremiumGenerator?: boolean | null;
  generatorPlan?: GeneratorCoarseLicenseLevel | null;
  backgroundImageId?: string | null;
  durationInMins?: number | null;
};
export type AiConsumerContent = {
  promptInputs: PromptInput[]; // the inputs consumers are exposed to
  categories: GeneratorCategory[] /** @deprecated */;
  promptInputSuggestions?: PromptInput[][];
  isPremiumGenerator?: boolean | null;
  generatorPlan?: GeneratorCoarseLicenseLevel | null;
  backgroundImageId?: string | null;
  durationInMins?: number | null;
};

export interface TemplateEditOrCreateDTO {
  createdAt?: number | null;
  id?: string | null;
  isPrivate?: boolean | null;
  parentId?: string | null;
  coverImageId?: string | null;
  coverImageAltText?: string | null;
  language?: string | null;
  title?: string | null;
  currentRound?: string | null;
  description?: string | null;
  templateSharingPublic?: boolean | null;
  isBlockedFromSharing?: boolean | null;
  modifiedAt: number;
  creator?: {
    uid?: string | null;
  };
  members?: string[] | null;
  rounds?: Round[] | null;
  slides?: Slide[] | null;
  requireRealNames?: boolean;
  clonedFromTemplateId?: string | null;
  metadata?: ById<string | boolean | number> | null;
  gameIds?: string[];
  activeGame?: {
    id: string;
    isOngoing: boolean;
  } | null;
  aiContent?: AiContent | null;
  attachments?: LessonAttachment[];
}

export type TemplateEditDTO = TemplateEditOrCreateDTO & { id: string };

export type Folder = {
  id: string;
  parentId?: string | null;
  clonedFromBlueprintId?: string | null;
  createdAt: number;
  isPrivate: boolean;
  modifiedAt: number;
  title?: string | null;
  description?: string | null;
  type: 'folder' | 'template';
  isDeleted?: boolean | null;
  deletedAt?: number | null;
  coverImageId?: string | null;
  coverImageAltText?: string | null;
  language?: string | null;
  requireRealNames?: boolean;
  creator: {
    uid: string;
  };
  members: string[];
};

export type Workspace = Folder;

export type WorkspaceEditDTO = Partial<Workspace> & {
  id: string;
};
export type FolderEditOrCreateDTO = {
  // TODO: remove Blueprint
  createdAt?: number | null;
  id?: string | null;
  isPrivate?: boolean | null;
  parentId?: string | null;
  title?: string | null;
  description?: string | null;
  requireRealNames?: boolean;
  language?: string | null;
  creator?: {
    uid?: string | null;
  };
  members?: string[] | null;
};

export type FolderBreadCrumb = {
  id: string;
  title: string;
  parentId?: string | null;
  children?: FolderBreadCrumb[];
};
