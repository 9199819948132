import { memo, useMemo } from 'react';
import styled from 'styled-components';

import StandardVerifiedSvg from '../../assets/icons/StandardVerifiedSvg';
import emptyTemplateCoverPhoto from '../../assets/images/emptyTemplateCoverPhoto.png';
import theme from '../../assets/theme';
import { LicenseCoarseLevels, UserAccessBadge } from '../../hooks/useAccessBadge';
import { useBreakPoint } from '../../hooks/useBreakPoint';
import BackendService from '../../services/backendService';
import { GeneratorCoarseLicenseLevel } from '../../services/TemplateService/Template.type';
import { IconWrapper } from '../_atoms/IconWrapper';
import PlanTypeLabel from '../_atoms/PlanTypeLabel';
import { Standard } from '../_organisms/DesignerTabPane/GeneratorsTab/utils';
import { AnimatedHoverContainer } from './AnimatedHoverContainer';

export const HorisontalGeneratorCard = ({
  title,
  coverImageId,
  status,
  onClick,
  firstPublished,
  isPremiumGenerator,
  generatorPlan,
  userAccessBadge,
  titleColor = theme.colors.black,
  description,
  learningStandards,
}: {
  coverImageId?: string | null;
  title: string;
  status?: string | null;
  onClick?: () => void;
  firstPublished?: number | null;
  isPremiumGenerator?: boolean | null;
  generatorPlan?: GeneratorCoarseLicenseLevel | null;
  userAccessBadge?: UserAccessBadge;
  titleColor?: string;
  description?: string;
  learningStandards?: Standard[];
}) => {
  const isStandardsAligned = useMemo(() => {
    if (!!description?.toLowerCase().includes('standards')) return true;
    if (!!learningStandards?.length) return true;
    return false;
  }, [description, learningStandards]);

  return (
    <AnimatedHoverContainer>
      <HorisontalGeneratorCardContainer onClick={onClick}>
        <HorisontalGeneratorImage
          coverImageId={coverImageId}
          status={status}
          firstPublished={firstPublished}
          isPremiumGenerator={isPremiumGenerator}
          generatorPlan={generatorPlan}
          userAccessBadge={userAccessBadge}
          isStandardsAligned={isStandardsAligned}
        />
        <HorisontalCardHeading $color={titleColor}>{title}</HorisontalCardHeading>
      </HorisontalGeneratorCardContainer>
    </AnimatedHoverContainer>
  );
};

const HorisontalCardHeading = styled.h3<{ $color?: string }>`
  height: 36px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  margin-top: 1px;
  margin-bottom: 0px;
  ${({ $color }) => $color && `color: ${$color};`}
  line-height: 18px;
  white-space: nowrap;

  @supports (-webkit-line-clamp: 2) {
    // adds ellipsis for webkit browsers. Other browser still cuts, but adds no ellipssis.
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  @media (max-width: 800px) {
    font-size: 14px;
    line-height: 12px;
  }
`;

export const HorisontalGeneratorImage = memo(
  ({
    coverImageId,
    status,
    firstPublished,
    isPremiumGenerator,
    generatorPlan,
    userAccessBadge,
    isStandardsAligned,
  }: {
    coverImageId?: string | null;
    status?: string | null;
    firstPublished?: number | null;
    isPremiumGenerator: boolean | undefined | null;
    generatorPlan?: GeneratorCoarseLicenseLevel | null;
    userAccessBadge?: UserAccessBadge;
    isStandardsAligned?: boolean;
  }) => {
    const isTexas = userAccessBadge?.organization?.region === 'TX';

    const isLargerThan800 = useBreakPoint(800);
    const generatorThumbnailId = coverImageId
      ? BackendService.instance.generatorPlugin.getGeneratorThumbnailUrl(coverImageId)
      : undefined;
    const isNew = useMemo(() => {
      if (!firstPublished) return false;
      const now = Date.now();
      const threeWeeks = 1000 * 60 * 60 * 24 * 21;
      return now - firstPublished < threeWeeks;
    }, [firstPublished]);
    return (
      <div style={{ position: 'relative' }}>
        <HorisontalGeneratorImg
          src={generatorThumbnailId ? generatorThumbnailId : emptyTemplateCoverPhoto.src}
        />

        {isLargerThan800 && isStandardsAligned && (
          <Label align="left">
            <IconWrapper width="12px" height="12px">
              <StandardVerifiedSvg />
            </IconWrapper>
            <span style={{ marginLeft: 5 }}>
              {isTexas ? 'TEKS aligned' : 'Standards aligned'}
            </span>
          </Label>
        )}
        {isPremiumGenerator && (
          <PlanTypeLabel
            userAccessBadge={userAccessBadge}
            isGeneratorCard={true}
            onlyIcon={true}
            generatorPlan={generatorPlan}
            isPremiumGenerator={isPremiumGenerator}
          />
        )}
        {status && status !== 'published' && (
          <StatusLabel align="left">{status}</StatusLabel>
        )}
        {isNew && <IsNewLabel align="right">New</IsNewLabel>}
      </div>
    );
  },
);
HorisontalGeneratorImage.displayName = 'HorisontalGeneratorImage';

const HorisontalGeneratorImg = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1.618; /* Golden ratio */
  border-radius: 8px;
  box-shadow: ${theme.effects.floatingCreatorElementShadow};
`;

const HorisontalGeneratorCardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  aspect-ratio: 1.28; // makes the width fill horisontal based on height as makes the image fit perfect with golden ratio
`;

const Label = styled.div<{ align: 'right' | 'left' }>`
  position: absolute;
  bottom: 5px;
  right: ${({ align }) => (align === 'right' ? '5px' : 'unset')};
  left: ${({ align }) => (align === 'left' ? '5px' : 'unset')};
  background: #00000099;
  border-radius: 10px;
  color: ${theme.colors.white};
  font-weight: 600;
  font-size: 10px;
  padding: 2px 10px;
  height: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px #00000025;
`;
const StatusLabel = styled(Label)`
  background: ${theme.colors.orange};
  top: 5px;
  bottom: unset;
`;
const IsNewLabel = styled(Label)`
  background: ${theme.colors.orange};
  top: 5px;
  bottom: unset;
`;
